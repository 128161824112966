<template>
  <div class="editAbout">
    <div class="notAva"><i class="el-icon-warning-outline"></i>暂无相关文档</div>
  </div>
</template>

<script>
export default {
  name: 'CpttAddAbout'
};
</script>

<style scoped>
.editAbout {
  position: relative;
}
@media screen and (min-width: 993px) {
  .editAbout {
    height: 350px;
  }
}
@media screen and (max-width: 992px) {
  .editAbout {
    height: 700px;
  }
}
.notAva {
  position: absolute;
  color: #a59e9e;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}
</style>
